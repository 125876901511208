import Swiper from 'swiper'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

export default function index(){
  if(window.innerWidth > 1119){
    
    const toLeftAnimation = document.getElementById('toLeftAnimation')
    const toRightAnimation = document.getElementById('toRightAnimation')
    const toRightAnimationSlides = document.getElementById('toRightAnimationSlides')
    if(toLeftAnimation && toRightAnimation && toRightAnimationSlides){
  
      ScrollTrigger.create({
        trigger: toLeftAnimation,
        pin: toLeftAnimation,
        start: "bottom bottom",
        end: "bottom top",
        invalidateOnRefresh: true,
        pinSpacing: false,
        //markers: true,
      })
  
      const toRightAnimationSlidesPin = document.getElementById('toRightAnimationSlidesPin')
      const slideChild = document.querySelectorAll('.second__slides__slide')
      const progressBar = document.querySelector('.second__pin__progress__bar')
  
      const slideCount = (slideChild.length - 1) * -100 + '%'
      const endSlidepoint = "+="+slideChild.length * 100+"%"
      const endPinPoint = "+="+slideChild.length * 150+"%"
  
      ScrollTrigger.create({
        trigger: toRightAnimation,
        pin: toRightAnimation,
        start: "top top",
        end: endPinPoint,
        invalidateOnRefresh: true,
        //markers: true,
      })
  
      ScrollTrigger.create({
        trigger: toRightAnimation,
        pin: toRightAnimationSlidesPin,
        start: "top top",
        end: endPinPoint,
        invalidateOnRefresh: true,
        pinSpacing: false,
        //markers: true,
      })
  
      gsap.fromTo(toRightAnimationSlides,
        {},
        {
          left: slideCount,
          scrollTrigger: {
            trigger: toLeftAnimation,
            start: "bottom center",
            end: endSlidepoint,
            scrub: 1,
            once: false,
            invalidateOnRefresh: true,
            //markers: true,
          }
        }
      )
  
      gsap.fromTo(progressBar,
        {},
        {
          width: '100%',
          scrollTrigger: {
            trigger: toLeftAnimation,
            start: "bottom center",
            end: endSlidepoint,
            scrub: 1,
            once: false,
            invalidateOnRefresh: true,
            //markers: true,
          }
        }
      )
    }
  
    gsap.utils.toArray(".toAnimeteParallax").forEach((el, index) => {
      gsap.fromTo(el,
        {},
        {
          y: '0',
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom top",
            scrub: 2,
            once: false,
            invalidateOnRefresh: true,
            //markers: true,
          }
        }
      )
    })
  }
}